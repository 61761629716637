<template>
  <v-dialog v-model="newDialog" max-width="700" persistent>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>new {{ name }} service</v-card-title>
      <v-card-text>
        <v-text-field v-model="add.name" dense label="name"></v-text-field>
        <v-text-field v-model="add.pricing" dense label="pricing" type="number"></v-text-field>
        <v-textarea
            v-model="add.description"
            counter="205"
            dense
            label="description"
            maxlength="205"
            outlined rows="4"
        ></v-textarea>

        <v-row>
          <v-col cols="12" sm="10">
            <v-file-input
                accept="image/png, image/jpeg"
                clearable
                label="Upload Service banner"
                show-size
                truncate-length="15"
                @change="changePix"
                @click:clear="pixClear"
            ></v-file-input>
          </v-col>
          <v-col cols="12" sm="2">
            <v-avatar color="#cfe0f8" size="65">
              <v-img :src="add.image"></v-img>
            </v-avatar>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        {{ myPix }}
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                :disabled="$v.add.$invalid"
                color="blue"
                small
                text
                @click="submit"
                v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Utils from "../../../mixins/utils.js";
import {validationMixin} from "vuelidate";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
  mixins: [Utils, validationMixin],
  props: {
    newDialog: Boolean,
    myId: {type: String},
    name: {type: String}
  },
  data() {
    return {
      loading: false,
      disabled: false,
      myPix: null,
      pix: 0,
      doctypeList: [],
      add: {
        subscriber_subsidiariesid: this.myId,
        name: null,
        description: null,
        image: null,
        pricing: null
      },
    };
  },
  validations: {
    add: {
      name: {
        required,
      },
      pricing: {
        required,
      },
      description: {
        required,
        maxLength: maxLength(240),
      },
      image: function () {
        if (this.pix === null) {
          return true;
        } else if (this.pix > 50000) {
          return false;
        } else {
          return true;
        }
      },
    },
  },
  watch: {
    pix: function (a) {
      if (a > 50000) {
        this.myPix = "Picture has to be less than 50KB (0.05MB) in size";
        this.disabled = true;
      } else if (a === null) {
        this.disabled = false;
        this.myPix = "";
      } else {
        this.disabled = false;
        this.myPix = "";
      }
    },
  },
  created() {
  },
  methods: {
    pixClear(e) {
      console.log(e);
      this.pix = "";
      this.add.image = "";
    },
    changePix(e) {
      if (e != null) {
        console.log("e:", e);
        console.log("size:", e.size);
        this.pix = e.size;
        this.getPix(e).then((response) => {
          let pix = response;
          this.add.image = pix;
          // console.log(this.add.fileName);
        });
      }
    },
    closeDialog() {
      let payload = {state: false};
      this.$emit("closeNew", payload);
    },
    submit() {
      let subscriber_subsidiariesid = parseInt(this.add.subscriber_subsidiariesid);
      let name = this.add.name;
      let pricing = this.add.pricing;
      let description = this.add.description;
      let image = this.add.image;
      let data = {subscriber_subsidiariesid, pricing, name, description, image};
      console.log("data:", data);
      let payload = {data: data, state: false};
      this.$emit("submitNew", payload);
    },
  },
};
</script>
