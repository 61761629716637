<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatApp" dense>
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
            :items="crumbs"
            class="capitalize"
            style="padding: 14px 20px"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn color="primary" icon @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <!-- <v-btn icon color="primary" @click="return">
          <v-icon>mdi-keyboard-return</v-icon>
        </v-btn> -->
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-toolbar dense flat outlined style="margin-bottom: 4px">
        <v-toolbar-title>{{ _decode(name) }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" text x-small @click.stop="newDialog = true">new service</v-btn>
        <ServiceNew :myId="_decode(id)" :name=" _decode(name)" :newDialog="newDialog" @closeNew="closeNew"
                    @submitNew="submitNew"/>
      </v-toolbar>
      <v-data-iterator
          :items="subServiceList"
          :items-per-page="6" :sort-by="id"
          :sort-desc="false" item-key="id"
      >
        <template v-slot:default="{ items }">
          <v-row>
            <v-col
                v-for="item in items"
                :key="item.id"
                cols="12"
                lg="4"
                md="4"
                sm="12"
            >
              <v-card outlined>
                <v-toolbar dense flat>
                  <v-toolbar-title>
                    {{ item.name }}
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-avatar size="36">
                    <v-img :src="item.image"/>
                  </v-avatar>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text style="padding: 0 16px">
                  <div class="my_text_list">
                    <ul>
                      <li>
                        <div class="title">pricing</div>
                        <div class="text">{{ currency(item.pricing) }}</div>
                      </li>
                      <li>
                        <div class="title">description</div>
                        <div class="text">{{ item.description }}</div>
                      </li>
                      <li>
                        <div class="title">active</div>
                        <div class="text">
                          <v-icon :color="item.active ? 'blue' : 'red'">
                            {{ item.active ? 'mdi-check' : 'mdi-close' }}
                          </v-icon>
                        </div>
                      </li>
                      <li>
                        <div class="title">created</div>
                        <div class="text">
                          {{ item.created | myDating }}
                        </div>
                      </li>
                      <li>
                        <div class="title">last updated</div>
                        <div class="text">
                          {{ item.updated | myDating }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="blue" text x-small
                         @click.stop="$set(editDialog, item.id, true)">edit
                  </v-btn>
                  <ServiceEdit
                      v-if="editDialog[item.id]"
                      :editDialog="editDialog[item.id]"
                      :myId="item.id"
                      :name="item.name"
                      @closeEdit="closeEdit"
                      @submitEdit="submitEdit"/>
                  <v-spacer></v-spacer>
                  <v-btn color="error" text x-small
                         @click.stop="$set(removeDialog, item.id, true)">remove
                  </v-btn>
                  <ServiceDelete
                      v-if="removeDialog[item.id]"
                      :myId="item.id"
                      :name="item.name"
                      :removeDialog="removeDialog[item.id]"
                      @closeRemove="closeRemove"
                      @submitRemove="submitRemove"/>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>

    </div>
    <v-snackbar
        v-model="snack.bar"
        :color="snack.color"
        :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import Util from "../../mixins/utils";
import ServiceNew from "../../components/subsidiaries/services/newone";
import ServiceEdit from "../../components/subsidiaries/services/edit.vue";
import ServiceDelete from "../../components/subsidiaries/services/remove.vue";

export default {
  props: {
    id: {type: String},
    name: {type: String},
  },
  components: {ServiceNew, ServiceEdit, ServiceDelete},
  mixins: [Util, WinScroll("position")],
  data() {
    return {
      newDialog: false,
      editDialog: {},
      updateDialog: {},
      viewDialog: {},
      removeDialog: {},
      shopMapDialog: {},
      crumbs: [
        {
          link: true,
          text: "Dashboard",
          to: {name: "lab.dash"},
        },
        {
          link: true,
          text: "Centers",
          to: {name: "lab.centers"},
        },
        {
          link: true,
          text: "Subsidiary",
          to: {name: "lab.centers.detail"},
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      subsidiary: {},
      subServiceList: [],
      headers: [
        {text: "name", value: "servicesName"},
        {text: "comment", value: "comment"},
        {text: "action", value: "id", align: "center"},
      ],
    };
  },
  computed: {
    flatApp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
    this.sublist();
  },
  methods: {
    list() {
      this.loading = true;
      let self = this;
      Restful.subscribers.subsidiary
          .find(this._decode(this.id))
          .then((response) => {
            console.log(response.data);
            this.subsidiary = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    sublist() {
      this.loading = true;
      let self = this;
      Restful.subscribers.subservice
          .list(this._decode(this.id))
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              this.subServiceList = response.data;
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    refresh() {
      this.list();
      this.sublist();
    },
    menulink() {
      this.$emit("sidebar");
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.subservice
          .create(p.data)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.sublist();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.newDialog = p.state;
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      console.log(p);
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.subservice
          .update(p.id, p.data)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.refresh();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeUpdate(p) {
      this.$set(this.updateDialog, p.id, p.state);
    },
    submitUpdate(p) {
      this.snack.bar = true;
      console.log('p:', p)
      // let self = this;
      // Restful.subscribers.subservice
      //   .update(p.id, p.data)
      //   .then((response) => {
      //     // console.log(response.data);
      //     this.snack.color = "success";
      //     this.snack.text = response.data;
      //     this.sublist();
      //   })
      //   .catch((error) => {
      //     console.log(error.response);
      //     self.snack.color = "error";
      //     self.snack.text = error.response.data;
      //   });
      this.$set(this.updateDialog, p.id, p.state);
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      let self = this;
      Restful.subscribers.subservice
          .remove(p.id)
          .then((response) => {
            // console.log(response.data);
            this.snack.color = "success";
            this.snack.text = response.data;
            this.sublist();
          })
          .catch((error) => {
            console.log(error.response);
            self.snack.color = "error";
            self.snack.text = error.response.data;
          });
      this.$set(this.removeDialog, p.id, p.state);
    },
    closeMap(p) {
      this.$set(this.shopMapDialog, p.id, p.state);
    },
  },
};
</script>
