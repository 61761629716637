<template>
  <v-dialog v-model="editDialog" max-width="700" persistent>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>update {{ name }}</v-card-title>
      <v-card-text>
        <v-text-field v-model="add.name" dense label="name"></v-text-field>
        <v-text-field v-model="add.pricing" dense label="pricing" type="number"></v-text-field>
        <v-textarea
            v-model="add.description"
            counter="205"
            dense
            label="description"
            maxlength="205"
            outlined
            rows="4"
        ></v-textarea>

        <v-row>
          <v-col cols="12" sm="10">
            <v-file-input
                accept="image/png, image/jpeg"
                clearable
                label="Upload Service banner"
                show-size
                truncate-length="15"
                @change="changePix"
                @click:clear="pixClear"
            ></v-file-input>
          </v-col>
          <v-col cols="12" sm="2">
            <v-avatar color="#cfe0f8" size="65">
              <v-img :src="add.image"></v-img>
            </v-avatar>
          </v-col>
        </v-row>

        <v-switch
            v-model="add.active"
            :label="`Active: ${add.active.toString()}`"
        ></v-switch>
      </v-card-text>
      <v-card-actions>
        {{ myPix }}
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                :disabled="$v.add.$invalid"
                color="blue"
                small
                text
                @click="submit"
                v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "../../../mixins/utils";
import {validationMixin} from "vuelidate";
import {maxLength, required} from "vuelidate/lib/validators";

export default {
  mixins: [Utils, validationMixin],
  props: {
    editDialog: Boolean,
    myId: {type: Number},
    name: {type: String}
  },
  data() {
    return {
      loading: false,
      disabled: false,
      myPix: null,
      pix: 0,
      doctypeList: [],
      add: {name: null, description: null, image: null, pricing: null, active: false},
    };
  },
  validations: {
    add: {
      name: {
        required,
      },
      pricing: {
        required,
      },
      active: {
        required,
      },
      description: {
        required,
        maxLength: maxLength(240),
      },
      image: function () {
        if (this.pix === null) {
          return true;
        } else if (this.pix > 50000) {
          return false;
        } else {
          return true;
        }
      },
    },
  },
  watch: {
    pix: function (a) {
      if (a > 50000) {
        this.myPix = "Picture has to be less than 50KB (0.05MB) in size";
        this.disabled = true;
      } else if (a === null) {
        this.disabled = false;
        this.myPix = "";
      } else {
        this.disabled = false;
        this.myPix = "";
      }
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers.subservice
          .find(this.myId)
          .then((response) => {
            console.log(response.data);
            this.add.name = response.data.name;
            this.add.description = response.data.description;
            this.add.image = response.data.image;
            this.add.active = response.data.active;
            this.add.pricing = response.data.pricing;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    pixClear(e) {
      console.log(e);
      this.pix = "";
      this.add.image = "";
    },
    changePix(e) {
      if (e != null) {
        console.log("e:", e);
        console.log("size:", e.size);
        this.pix = e.size;
        this.getPix(e).then((response) => {
          let pix = response;
          this.add.image = pix;
          // console.log(this.add.fileName);
        });
      }
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeEdit", payload);
    },
    submit() {
      let name = this.add.name;
      let description = this.add.description;
      let image = this.add.image;
      let active = this.add.active;
      let pricing = this.add.pricing;
      let data = {name, description, image, active, pricing};
      console.log("data:", data);
      let payload = {id: this.myId, data: data, state: false};
      this.$emit("submitEdit", payload);
    },
  },
};
</script>
